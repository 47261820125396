<template>
  <span>{{ c }}</span>
</template>

<script>
export default {
  name: 'number-roller',
  props: {
    num: {
      type: Number,
      required: true   //数字
    },
    bit: {
      type: Number,  //小数点后
      default: 0
    },
    prefix: {
      type: String,
      default: ''  //前面单位符号
    },
    suffix: {
      type: String,//后面单位符号
      default: ''
    },
    duration: {
      type: Number, //时间
      default:6000
    },
    delay: {
      type: Number, //延迟
      default: 0
    }
  },
  watch: {
    num () {
      this.count()
    }
  },
  data () {
    return {
      d: 0,
      i: 100
    }
  },
  computed: {
    c () {
      return this.prefix + this.d.toFixed(this.bit) + this.suffix
    }
  },
  methods: {
    count () {
      let num = this.num
      let duration = this.duration
      let i = this.i
      let times = Math.ceil(duration / i)
      let step = num / times
      let temp = 0
      this.ti = setInterval(() => {
        if (times < 1 || (temp >= num - step)) {
          this.d = num
          this.clear()
          return
        }
        this.d = temp += step
        times--
      }, i)
    },
    clear () {
      clearInterval(this.ti)
      clearTimeout(this.to)
    }
  },
  mounted () {
    this.to = setTimeout(this.count, this.delay)
  }
}
</script>